@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl font-bold;
  }

  h2 {
    @apply text-2xl font-bold;
  }
}

@layer utilities {
  .content-fit {
    width: fit-content;
  }

  .numeric-same-width {
    font-variant-numeric: tabular-nums;
  }
}

@layer components {
  .btn {
    @apply inline-flex cursor-pointer items-center justify-center space-x-1 rounded-md bg-primary py-2 px-5 text-sm text-white hover:opacity-80;
  }

  .dashboard-page {
    @apply min-h-[60vh] rounded-md bg-white p-8 shadow-custom;
  }
}

@layer base {
  /** Nunito */
  @font-face {
    font-family: 'Nunito';
    src: url('./assets/fonts/nunito/Nunito-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('./assets/fonts/nunito/Nunito-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Nunito';
    src: url('./assets/fonts/nunito/Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Nunito';
    src: url('./assets/fonts/nunito/Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Nunito';
    src: url('./assets/fonts/nunito/Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  /** Satoshi */
  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/satoshi/Satoshi-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  /** Inter */
  @font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/inter/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/inter/Inter-Bold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/inter/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/inter/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  .map-popup,
  .mapboxgl-popup-content {
    padding: 0 !important;
    width: 300px;
  }

  .mapboxgl-popup-tip {
    display: none !important;
  }
}

.input-phone-number input:focus {
  outline: none !important;
}

.input-phone-number:focus {
  border: 1px solid #3bce49 !important;
}

.spinner_ajPY {
  transform-origin:center;
  animation:spinner_AtaB .75s infinite linear
}

@keyframes spinner_AtaB {
  100% { transform: rotate(360deg) }
}
