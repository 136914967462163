#print-hide,
#greendot-footer {
  display: none;
}

body > #print-hide {
  display: block;
}

#barcode-container {
  
}
@media print {
  #print-hide {
    display: block;
  }

  #barcode-container {
    display: flex;
    justify-content: space-between;
  }

  #greendot-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  #greendot-footer {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
  }

  #greendot-footer-support {
    position: fixed;
    right: 0;
    margin-right: 10px;
  }

  #print-container {
    margin: 0 2em;
    margin-top: 6em;
  }
}
